.report-container-light {
    height: calc(100vh - 120px);
    width: 100%;
    background-color: #ffffff;
}

.report-container-dark {
    height: calc(100vh - 120px);
    width: 100%;
    background-color: transparent;
}