/* Syncfusion CSS */
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-grids/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-react-pivotview/styles/material.css';

#PivotView {
  min-height: 0;
  max-height: 80vh;
  /* height: auto !important; */
}

#PivotView-Heatmap .e-group-columns {
  display: none;
}

#PivotView-Heatmap .e-group-rows {
  display: none;
}

#PivotView-Heatmap .e-group-values {
  display: none;
}

#PivotView-Heatmap .e-group-filters {
  display: none;
}

.range-midpoint {
  background-color: #ffffff !important;
  border-color: #e0e0e0 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range1 {
  background-color: #ffe484 !important;
  border-color: #ffe484 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range2 {
  background-color: #ffcc7c !important;
  border-color: #ffcc7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range3 {
  background-color: #ffac7c !important;
  border-color: #ffac7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range4 {
  background-color: #ffa474 !important;
  border-color: #ffa474 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range5 {
  background-color: #ff8474 !important;
  border-color: #ff8474 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range6 {
  background-color: #ff6c6c !important;
  border-color: #ff6c6c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range7 {
  background-color: #ffe484 !important;
  border-color: #ffe484 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range8 {
  background-color: #f8e484 !important;
  border-color: #f8e484 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range9 {
  background-color: #e0dc84 !important;
  border-color: #e0dc84 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range10 {
  background-color: #b8d47c !important;
  border-color: #b8d47c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range11 {
  background-color: #a8d47c !important;
  border-color: #a8d47c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range12 {
  background-color: #8bc97d !important;
  border-color: #8bc97d !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range13 {
  background-color: #68bc7c !important;
  border-color: #68bc7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

.range14 {
  background-color: #66bb71 !important;
  color: black !important;
  font-weight: 500;
  border-color: #66bb71 !important;
  text-shadow: 0px 0px black;
}